import { graphql, navigate, PageProps } from "gatsby"
import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getRandomIntInclusive } from "../util/mathUtils"

export default function IndexPage({ data: { allFennec } }: PageProps<QueryData>) {
  useEffect(() => {
    // navigate to a random fennec picture
    const randomFennecIdx = getRandomIntInclusive(1, parseInt(allFennec.totalCount, 10))

    // replacing history so that the initial navigation to `/` looks like it didn't happen
    navigate(`/${randomFennecIdx}`, { replace: true })
  })

  // select a random fennec for the SEO tag (will end up being selected at random at build time)
  const seoFennecIndex = getRandomIntInclusive(1, parseInt(allFennec.totalCount, 10))
  const fennec = allFennec.nodes[seoFennecIndex]

  return (
    <Layout>
      <SEO title={`#${fennec.parent.name}`} image={fennec.parent.childImageSharp.fixed.src} />
    </Layout>
  )
}

interface QueryData {
  allFennec: {
    totalCount: string
    nodes: {
      parent: {
        name: string
        childImageSharp: {
          fixed: {
            src: string
          }
        }
      }
      colors: {
        vibrant: string
        muted: string
        lightVibrant: string
        lightMuted: string
        darkVibrant: string
        darkMuted: string
      }
    }[]
  }
}

export const query = graphql`
  query {
    allFennec {
      totalCount
      nodes {
        parent {
          ... on File {
            name
            childImageSharp {
              fixed(toFormat: PNG, width: 1000) {
                src
              }
            }
          }
        }
        colors {
          vibrant
          muted
          lightVibrant
          lightMuted
          darkVibrant
          darkMuted
        }
      }
    }
  }
`
